(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name myPizza.controller:MyPizzaCtrl
   *
   * @description
   *
   */
  angular
    .module('myPizza')
    .controller('MyPizzaCtrl', MyPizzaCtrl);

  function MyPizzaCtrl($scope, $rootScope, $timeout, $mdDialog, MyPizza) {
    $rootScope.pageTitle = "הפיצריה שלי";

    $scope.initGallery = function() {
      if ($scope.franchiseGraphics.gallery != undefined) {
        //  if ($(".franchise-gallery").hasClass("slick-slider")) {
        //    $('.franchise-gallery').slick('unslick');
        //}
        $timeout(function() {
          $(".franchise-gallery").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: "#left-arrow",
            nextArrow: "#right-arrow",
            centerMode: true
          });
        }, 50);

      }
    }

    $scope.contactUsDialog = function() {
      $scope.slideUp();
      $mdDialog.show({
        templateUrl: 'my-pizza/contact-us-dialog.html',
        scope: $scope,
        preserveScope: true
      });
    }

    $scope.contactUs = function(text) {
      MyPizza.contactUs(text, $scope.getItemFromLocalStorage("loginToken")).then(function(response) {
        //TODO
      });
    }

    $rootScope.$on("graphicsReady", $scope.initGallery)

    $scope.initGallery();

  }
}());
